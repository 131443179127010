import React from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/ui/breadcrumbs"
import pillar from "../../../static/images/pillars/pillar-1.jpg"

class RootIndex extends React.Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Seo title="Pillar 1" />
                <div className="container single-pillar">
                    <img src={pillar} alt="man raking leaves" />
                    <Breadcrumbs loc="pillars" subloc="pillar 1" />
                    <h1>Pillar 1</h1>
                    <h2>Gain strength for a functional everyday moments</h2>
                    <p>
                        Having strong muscles can mean the difference between
                        only being able to complete a 10-minute walk versus an
                        hour long walk or getting off a chair or couch
                        independently versus needing assistance. Using exercise
                        to build strong muscles is going to help you keep doing
                        all the things you love like playing with your
                        grandchildren, gardening, walking with minimal
                        discomfort or pain. The key to doing this is to build
                        muscle strength! Muscle mass naturally decreases with
                        age but strength training can help slow that process
                        down or even reverse it.{" "}
                    </p>

                    <h4>
                        Here are some benefits
                        <br />
                        of strength training among many others:
                    </h4>
                    <h3>Strength training increases muscle mass</h3>
                    <p>
                        By the age of 70, the average adult has lost 25 percent
                        of muscle mass. And this is due mostly to disuse and
                        inactivity. Any kind of exercise can reverse this loss
                        and build muscle mass and strength, but weight lifting,
                        strength training, and resistance training are best.
                    </p>
                    <h3>
                        Increases bone density and reduces risk of bone
                        fractures{" "}
                    </h3>
                    <p>
                        Bone fractures and breaks are all too common in older
                        adults because of loss of bone density and osteoporosis.
                        While there can be other causes of osteoporosis, and it
                        may need to be managed medically, there is plenty of
                        evidence that exercise can improve bone density.
                        Weight-bearing aerobic exercise and strength training
                        increase density and reduce the risks of breaks
                    </p>
                    <h3>Improving Functional Movement</h3>
                    <p>
                        Increasing strength through training is essential for
                        improving overall function. Older adults can gain more
                        mobility, walk farther, and even reduce the need for
                        assistive devices like canes and walkers with regular
                        strength training.
                    </p>
                    <p>
                        Building strength also helps with all kinds of other
                        functional movements, like sitting or getting in the
                        bathtub. This in turn just makes life easier and opens
                        up access to more activities.
                    </p>
                    <h3>Better Body Composition</h3>
                    <p>
                        Older adults, tend to gain more fat while they lose
                        muscle mass. This puts them at risk for chronic
                        illnesses. All kinds of exercise help to maintain good
                        body composition, and strength training is an important
                        component.
                    </p>
                    <h3>
                        Strength Training for Seniors Benefits Mental Health
                    </h3>
                    <p>
                        Just as important as physical health in aging is mental
                        health. Getting older can put you at risk for loneliness
                        and social isolation, depression, and other mental
                        health issues. Building strength, which increases
                        mobility and function and improves overall health,
                        boosts mood and overall quality of life.
                    </p>
                    <Link className="button" to="/pillars/pillar-2/">
                        Next pillar
                    </Link>
                </div>
            </Layout>
        )
    }
}

export default RootIndex
